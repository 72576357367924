 header img {
  width: 400px;
  height: 100%;
 
 margin-top: -100px;
  
}
.navbar-nav {
  display: grid;
  grid-template-columns: 90px 160px 160px;
  justify-content: end;
}

.navbar-nav .nav-item {
  text-align: center;
  margin-top: 10px;
}

.navbar-brand h2 {
  color: #fff;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-top: 10px;
}
.navbar-brand {
  float: left;
  margin-top: 12px;
  outline: none;
}

a.nav-link:hover {
  border-bottom: 4px solid #8b4513;
  color: #8b4513;
}
a.nav-link {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #fff;
  transition: all 0.5s;
  margin-top: 10px;
}

.nav-element:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  display: none;
  opacity: 0;
  font-size: 1.8rem;
}

@media only screen and (max-width: 900px) {
  header .nav-btn {
    display: inline;
    opacity: 1;
    color: #fff;
    position: absolute;
    right: 30px;
    top: 20px;
  }
  .nav-close-btn {
    position:relative;
    top: 2rem;
    right: 2rem;
   }

  header nav {
    position: fixed;
    top: -110vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    /* transition: 1s; */
  }
  header img{
    width: 200px;
    margin-top: -50px;
  }
  .navbar-nav {
    display: flex;
  }
  nav {
    padding: 0 5vh 0 5vh;
    height: 100%;
    display: flex;
    list-style-type: none;
  }

  .nav-link {
    font-size: 2vh;
    color: #8b4513;
    text-decoration: none;
    margin: 1vh;
  }

  .navbar-nav .nav-item {
    border-bottom: 1px solid #eee;
    margin: 0;
  }

  .responsive_nav {
    display: flex;
    z-index: 99999;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    opacity: 0.7;
    padding: 160px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    transition: 1s;
    margin-top: 20px;
  }

  .navbar-nav a.nav-link {
    padding: 15px 0px;
    color: #1e1e1e !important;
  }
  .navbar-nav .nav-link:hover,
  .navbar-nav .active > .nav-link,
  .navbar-nav .nav-link.current,
  .navbar-nav .nav-link.show,
  .navbar-nav .show > .nav-link {
    color: #8b4513 !important;
    border-bottom: none !important;
  }
}

@media (max-width: 600px) {
header img {
  width: 150px;
margin-top: -30px;
}


  .navbar-nav .nav-item {
    border-bottom: 1px solid #eee;
  }

  .responsive_nav {
    display: flex;
    z-index: 99999;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 90px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  }

  .navbar-nav a.nav-link {
    padding: 5px 0px;
    color: #1e1e1e !important;
  }
  .navbar-nav .nav-link:hover,
  .navbar-nav .active > .nav-link,
  .navbar-nav .nav-link.current,
  .navbar-nav .nav-link.show,
  .navbar-nav .show > .nav-link {
    color: #8b4513 !important;
    border-bottom: none !important;
  }
}
