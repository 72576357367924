

  
/* Carousel.css */
/* .text{
  position: absolute;
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
 
  
  font-size: 60px;
  font-weight: 900;
  -webkit-text-fill-color: #8B4513;
  -webkit-text-stroke: 2px white;
} */
/* .text {
  position: absolute;
  font-size: 20px;
	font-weight: 700;
	letter-spacing: 0.25px;
	margin-bottom: 15px;

  margin: 0;
  padding: 0;
  font-size: 60px;
  color: #ffffff;
  text-shadow: 2px 2px #8B4513;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  text-transform: uppercase;
  transition: 0.5s;
  /* filter: alpha(opacity=60);
  opacity: 0.6; }*/


/* .text:hover {
  letter-spacing: 11px;
}  */


.full-width-carousel {
	width: 100%;
	margin: 0 auto;
  
  
  
}

.full-width-slide {
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
  
  
}

.full-width-slide img {
	max-width: 100%;
	max-height: 80vh;
	object-fit: cover;
 

  

}

.slide-image {
	width: 100%;
	height: auto;
  display: block;
  
}

.carousel .control-dots .dot {
  border-radius: 0% !important;
  width: 30px !important;
  height: 5px !important;
  background: #8B4513;
 
}
.carousel .carousel-status {
  opacity: 0;
}
.carousel .control-next.control-arrow:before {
  content: '';
  margin: 20px;
  border: solid #8B4513;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  
}

.carousel .control-prev.control-arrow:before {
  content: '';
  margin: 20px;
  border: solid #8B4513;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  
}


  